$(document).ready(function(){

    var options = {
        title: '&#x1F36A; &nbsp;Accept Cookies & Privacy Policy?',
        message: 'We use cookies to give you the best experience by remembering your preferences and repeat visits. By clicking <strong>Accept</strong>, you consent to the use of ALL the cookies.',
        delay: 600,
        expires: 1,
        link: 'https://web.thinkladder.com/terms-of-use/',
        onAccept: function(){
            var myPreferences = $.fn.ihavecookies.cookie();
            console.log('Yay! The following preferences were saved...');
            console.log(myPreferences);
        },
        uncheckBoxes: true,
        acceptBtnLabel: 'Accept Cookies',
        moreInfoLabel: 'More information',
        cookieTypesTitle: 'Select which cookies you want to accept',
        fixedCookieTypeLabel: 'Essential',
        fixedCookieTypeDesc: 'These are essential for the website to work correctly.'
    }

    $(document).ready(function() {
        $('body').ihavecookies(options);

        if ($.fn.ihavecookies.preference('marketing') === true) {
            console.log('This should run because marketing is accepted.');
        }

        $('#ihavecookiesBtn').on('click', function(){
            $('body').ihavecookies(options, 'reinit');
        });
    });



    
    // 🅑 🅡 🅘 🅐

    // disable the tile and animate while submitting
    $('.tile__button').on('click', function(){
        $(this).find('i.fa').removeClass('fa-arrow-right').addClass('fa-spinner fa-spin');
        $(this).prop('disabled', true);
        $(this).closest('form').submit();
    });

    // show the flag panel for users to flag items
    $('.flag-icon').on('click', function(){
        $(this).closest('.tile').find('.flag-panel').addClass('flag-panel--visible')
        return false;
    });

    // close the flag panel when the user clicks its close button
    $('.flag-icon--close').on('click', function(){
        $(this).closest('.tile').find('.flag-panel').removeClass('flag-panel--visible')
        return false;
    });


    // send up the flagged info.
    $('.flag-link').on('click', function(){

        let report_data = {
            'report_category' : $(this).data('report_category'),
            'reported_content_type' : $(this).data('reported_content_type'),
            'content' : $(this).data('content')
        }

        // post up report
        $.post( "/bria/report", report_data, function( data ) {});

        // not waiting for response
        $(this).closest('.tile').addClass('tile--reported');
        $(this).closest('.tile').find('.flag-panel').removeClass('flag-panel--visible');


        return false;
    });



})